import { type FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import type { BoxProps } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as copilotStore from 'widgets/Copilot/store';

import DropdownMenu from 'ui/DropdownMenu';
import * as askStore from 'store/nodes/ask';
import { useRoute } from 'navigation/hooks';

export type ModelSelectProps = Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'>;

const ModelSelect: FC<ModelSelectProps> = (props) => {
  const dispatcher = useDispatcher();

  const selectedModel = useSelector(copilotStore.selectors.selectedModel);
  const availableModels = useSelector(copilotStore.selectors.availableModels);
  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping('*'));

  const handleChange = useCallback((id: number) => {
    dispatcher.copilot.setSelectedModel({ id: Number(id) });
  }, []);

  // временное решение o1 только на экране ask и для research {
  const route = useRoute();

  const scope = useSelector(askStore.selectors.scope);
  const filteredAvailableModels = useMemo(() => {
    if (scope === 'research' && route.isScreen('Ask')) {
      return availableModels;
    }
    return availableModels.filter((item) => !item.model.includes('o1'));
  }, [availableModels, scope, route]);

  useEffect(() => {
    if (selectedModel && (scope !== 'research' || !route.isScreen('Ask')) && selectedModel.model.includes('o1')) {
      handleChange(0);
    }
  }, [selectedModel, scope, route]);
  // }

  return (
    <>
      {isShow && selectedModel && (
        <DropdownMenu
          disabled={isAnswerTyping || filteredAvailableModels.length === 1}
          options={filteredAvailableModels}
          value={selectedModel.id}
          optionValueName="id"
          optionLabelName="model"
          onChange={handleChange}
          {...props}
        />
      )}
    </>
  );
};

export default memo(ModelSelect, (prevProps, nextProps) => isEqual(prevProps, nextProps));
